import { FastField, FieldArray } from "formik";
import DisplayHeadingForMultipleFields from "../../common/DisplayHeadingForMultipleFields";
import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  BaseCountrySelect,
  BaseDatePicker,
  BaseSelect,
  BaseTextField,
} from "../../../base";
import { InsertDriveFileOutlined, PlaceOutlined } from "@mui/icons-material";
import { PhoneNumberTextField } from "../../../../common/Form/PhoneNumberTextField/PhoneNumberTextField";
import BaseUploadNew from "../../../base/BaseUploadNew";
import { addAndRemoveImageHanlderArray } from "../../utils/addAndRemoveImageHandlerArray";
import { useDispatch } from "react-redux";
import {
  checkIfFieldToBeDisplayedOrNot,
  getDynamicFieldLabelName,
  getDynamicFieldLabelNameBasedOnCountryId,
} from "../../utils/sectionFieldsAndLabelsUtils";
import { useMemo } from "react";
import { toDisplayAddressDetailsSubSection } from "../utils/toDisplayAddressDetailsSubSection";

const ProfessionalFormDetails = ({
  form,
  inputFields,
  pushNewProfRef,
  qualificationStatuses,
  professionalForm,
  setDisableBtn,
  attachFileErr,
  setAttachFileErr,
}) => {
  const dispatch = useDispatch();

  let isAddressDetailsfieldsPresent = useMemo(
    () => toDisplayAddressDetailsSubSection(inputFields),
    [inputFields]
  );
  return (
    <FieldArray name="qualifications">
      {({ push, remove }) => {
        pushNewProfRef.current = push;
        return (
          <>
            {form.values.qualifications?.length ? (
              <>
                {form.values.qualifications.map((qualification, index) =>
                  qualification.selectedTab ? (
                    <>
                      <DisplayHeadingForMultipleFields
                        index={index}
                        heading=" Professional Qualification"
                        currentValue={qualification}
                        sectionValues={form?.values?.qualifications}
                        fieldArrayName="qualifications"
                        setFieldValue={form.setFieldValue}
                      />

                      <Box px={3}>
                        <Box>
                          <Grid container spacing={2}>
                            {checkIfFieldToBeDisplayedOrNot(
                              "professionalQualificationTitle",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={6}>
                                <FastField
                                  component={BaseTextField}
                                  name={`qualifications.${index}.professionalQualificationTitle`}
                                  label={getDynamicFieldLabelName(
                                    "professionalQualificationTitle",
                                    inputFields
                                  )}
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "qualifyingInstituteBodyName",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={6}>
                                <FastField
                                  component={BaseTextField}
                                  name={`qualifications.${index}.qualifyingInstituteBodyName`}
                                  label={getDynamicFieldLabelName(
                                    "qualifyingInstituteBodyName",
                                    inputFields
                                  )}
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "statusProfessionalQualificationId",
                              inputFields
                            ) && (
                              <>
                                <Grid item xs={12} md={6}>
                                  <FastField
                                    component={BaseSelect}
                                    options={qualificationStatuses}
                                    label={getDynamicFieldLabelName(
                                      "statusProfessionalQualificationId",
                                      inputFields
                                    )}
                                    optionLabel="statusProfessionalQualificationName"
                                    optionValue="candidatesProfessionalQualificationsStatusId"
                                    name={`qualifications.${index}.statusProfessionalQualificationId`}
                                  />
                                </Grid>
                                {qualification.statusProfessionalQualificationId ===
                                4 ? (
                                  <Grid item xs={12} md={6}>
                                    <FastField
                                      component={BaseTextField}
                                      name={`qualifications.${index}.otherProffesionalQualificationStatus`}
                                      label="Other Qualification Status*"
                                    />
                                  </Grid>
                                ) : null}
                              </>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "membershipQualificationNumber",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={6}>
                                <FastField
                                  component={BaseTextField}
                                  name={`qualifications.${index}.membershipQualificationNumber`}
                                  label={getDynamicFieldLabelName(
                                    "membershipQualificationNumber",
                                    inputFields
                                  )}
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "professionalFromDate",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={4}>
                                <FastField
                                  component={BaseDatePicker}
                                  name={`qualifications.${index}.professionalFromDate`}
                                  label={getDynamicFieldLabelName(
                                    "professionalFromDate",
                                    inputFields
                                  )}
                                  views={["year", "month", "day"]}
                                  placeholder="DD-MMM-YYYY"
                                  inputFormat="dd-MMM-yyyy"
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "professionalToDate",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={4}>
                                <FastField
                                  component={BaseDatePicker}
                                  name={`qualifications.${index}.professionalToDate`}
                                  label={getDynamicFieldLabelName(
                                    "professionalToDate",
                                    inputFields
                                  )}
                                  views={["year", "month", "day"]}
                                  placeholder="DD-MMM-YYYY"
                                  inputFormat="dd-MMM-yyyy"
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "dateOfAccreditation",
                              inputFields
                            ) && (
                              <Grid item xs={12} md={4}>
                                <FastField
                                  component={BaseDatePicker}
                                  name={`qualifications.${index}.dateOfAccreditation`}
                                  label={getDynamicFieldLabelName(
                                    "dateOfAccreditation",
                                    inputFields
                                  )}
                                  views={["year", "month", "day"]}
                                  placeholder="DD-MMM-YYYY"
                                  inputFormat="dd-MMM-yyyy"
                                />
                              </Grid>
                            )}
                            {checkIfFieldToBeDisplayedOrNot(
                              "professionalAdditionalComments",
                              inputFields
                            ) && (
                              <Grid item xs={12}>
                                <FastField
                                  rows={4}
                                  multiline
                                  component={BaseTextField}
                                  name={`qualifications.${index}.professionalAdditionalComments`}
                                  label={getDynamicFieldLabelName(
                                    "professionalAdditionalComments",
                                    inputFields
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>

                        {/* Address Of Institute/ Body */}
                        <Box
                          display={
                            isAddressDetailsfieldsPresent ? "block" : "none"
                          }
                        >
                          <Box my={3} px={3}>
                            <Divider />
                          </Box>

                          <Box
                            mt={3}
                            display={"flex"}
                            alignItems="center"
                            className="space-x-2"
                          >
                            <PlaceOutlined />
                            <Typography fontSize={"20px"} fontWeight="500">
                              Address Of Institute/ Body
                            </Typography>
                          </Box>
                          <Box mt={3}>
                            <Grid container spacing={2}>
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalStreetAddressLine1",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={6}>
                                  <FastField
                                    component={BaseTextField}
                                    name={`qualifications.${index}.professionalStreetAddressLine1`}
                                    label={getDynamicFieldLabelName(
                                      "professionalStreetAddressLine1",
                                      inputFields
                                    )}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalStreetAddressLine2",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={6}>
                                  <FastField
                                    component={BaseTextField}
                                    name={`qualifications.${index}.professionalStreetAddressLine2`}
                                    label={getDynamicFieldLabelName(
                                      "professionalStreetAddressLine2",
                                      inputFields
                                    )}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalCityTownDistrict",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={3}>
                                  <FastField
                                    component={BaseTextField}
                                    name={`qualifications.${index}.professionalCityTownDistrict`}
                                    label={getDynamicFieldLabelName(
                                      "professionalCityTownDistrict",
                                      inputFields
                                    )}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalStateProvince",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={3}>
                                  <FastField
                                    component={BaseTextField}
                                    name={`qualifications.${index}.professionalStateProvince`}
                                    shouldUpdate={() => true}
                                    label={`${getDynamicFieldLabelName(
                                      "professionalStateProvince",
                                      inputFields,
                                      false
                                    )}${
                                      // form?.values?.qualifications[index]
                                      //   ?.professionalCountryId === 101 ||
                                      // form?.values?.qualifications[index]
                                      //   ?.professionalCountryId === 132
                                      //   ? ""
                                      //   : "*"
                                      getDynamicFieldLabelNameBasedOnCountryId(
                                        "professionalStateProvince",
                                        inputFields,
                                        form?.values?.qualifications[index]
                                          ?.professionalCountryId
                                      )
                                    }`}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalCountryId",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={3}>
                                  <FastField
                                    component={BaseCountrySelect}
                                    name={`qualifications.${index}.professionalCountryId`}
                                    label={getDynamicFieldLabelName(
                                      "professionalCountryId",
                                      inputFields
                                    )}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "professionalZipPostalAreaCode",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={3}>
                                  <FastField
                                    component={BaseTextField}
                                    name={`qualifications.${index}.professionalZipPostalAreaCode`}
                                    label={getDynamicFieldLabelName(
                                      "professionalZipPostalAreaCode",
                                      inputFields
                                    )}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "institutePhoneNumber",
                                inputFields
                              ) && (
                                <Grid item xs={12} md={3}>
                                  <Typography
                                    varient="h5"
                                    sx={{
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {getDynamicFieldLabelName(
                                      "institutePhoneNumber",
                                      inputFields
                                    )}
                                  </Typography>
                                  <FastField
                                    name={`qualifications.${index}.institutePhoneNumber`}
                                    codeName={`qualifications.${index}.institutePhoneCountryCode`}
                                    placeholder="Your phone number"
                                    className="round_phone"
                                    component={PhoneNumberTextField}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Box>

                        {/* Supporting Documents */}
                        {checkIfFieldToBeDisplayedOrNot(
                          "attachmentsProfessional",
                          inputFields
                        ) && (
                          <Box mb={3}>
                            <Box my={3} px={3}>
                              <Divider />
                            </Box>

                            <Box
                              mt={3}
                              display={"flex"}
                              alignItems="center"
                              className="space-x-2"
                            >
                              <InsertDriveFileOutlined />
                              <Typography fontSize={"20px"} fontWeight="500">
                                Supporting Documents
                                {getDynamicFieldLabelName(
                                  "attachmentsProfessional",
                                  inputFields
                                )?.includes("*")
                                  ? "*"
                                  : ""}
                              </Typography>
                            </Box>

                            <BaseUploadNew
                              multiple
                              mt={3}
                              label={getDynamicFieldLabelName(
                                "attachmentsProfessional",
                                inputFields
                              )}
                              onChange={(files, delObj, delAll) =>
                                addAndRemoveImageHanlderArray({
                                  files,
                                  delObj,
                                  delAll,
                                  form,
                                  refForm: professionalForm,
                                  index,
                                  keyForArray: "qualifications",
                                  categoryName: "PROFESSIONAL_DOCS",
                                  dispatch,
                                  setDisableBtn,
                                })
                              }
                              categoryName="PROFESSIONAL_DOCS"
                              id={`accordion${form.values.qualifications.length}`}
                              uploadFiles={qualification?.attachments}
                              form={form}
                              name={`qualifications.${index}.attachments`}
                              checkName={`qualifications.${index}.attachments`}
                              attachFileErr={attachFileErr}
                              setAttachFileErr={setAttachFileErr}
                              key={index}
                              setDisableBtn={setDisableBtn}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  ) : null
                )}

                {/* Add More Professional Qualification */}
                {/* <Stack
                  my={3}
                  px={3}
                  spacing={3}
                  direction={{ xs: "column", md: "row" }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="That's All, I don't have any other Professional Qualification"
                  />
                </Stack> */}
              </>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography align="center" fontWeight={550} p={2}>
                  No Professional Qualification Present
                </Typography>
              </Box>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

export default ProfessionalFormDetails;
