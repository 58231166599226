export const routeUserAsPerRole = (res, navigate, location) => {
  if (res?.roleId === 4) {
    navigate("/ops/dashboard");
  } else if (res.roleId === 3) {
    navigate("/candidate/profile");
  } else if (
    ((!res.hrOrganization && res?.hrTeams?.length === 0) || !res?.userName) &&
    res.roleId === 2
  ) {
    navigate("/hr/first-login");
  } else if (res.roleId === 2) {
    if (location) {
      navigate(`/hr/dashboard${location.search}`);
    } else {
      navigate("/hr/dashboard");
    }
  } else if (res.roleId === 1) {
    navigate("/system-admin/home");
  } else {
    if (location) {
      navigate(`/login${location.search}`);
    } else {
      navigate("/login");
    }
  }
};
