import {
  CheckBox,
  CheckBoxOutlineBlank,
  DeleteOutline,
  InfoOutlined,
  InsertDriveFileOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FastField, Field, FieldArray } from "formik";
import moment from "moment";
import React, { useMemo } from "react";
import {
  BaseCountrySelect,
  BaseCurrencyAutocomplete,
  BaseDatePicker,
  BaseRadioGroup,
  BaseSelect,
  BaseSwitchCard,
  BaseTextField,
} from "../../../base";

import DisplayHeadingForMultipleFields from "../../common/DisplayHeadingForMultipleFields";
import { checkGaps } from "../utils/checkGaps";
import CustomTooltip from "../../../common/CustomTooltip";
import HandleToRenderReasonForCessationDateField from "./HandleToRenderReasonForCessationDateField";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { PhoneNumberTextField } from "../../../../common/Form/PhoneNumberTextField/PhoneNumberTextField";
import BaseUploadNew from "../../../base/BaseUploadNew";
import { addAndRemoveImageHanlderArray } from "../../utils/addAndRemoveImageHandlerArray";
import { newBonusData } from "../helpers/initialValues";
import {
  checkIfFieldToBeDisplayedOrNot,
  getDynamicFieldLabelName,
  getDynamicFieldLabelNameBasedOnCountryId,
} from "../../utils/sectionFieldsAndLabelsUtils";
import { toDisplaySupervisorDetailsSubSection } from "../utils/toDisplaySupervisorDetailsSubSection";
import { toDisplayCompanyDetailsSubSection } from "../utils/toDisplayCompanyDetailsSubSection";
import { toDisplayHrDetailsSubSection } from "../utils/toDisplayHrDetailsSubSection";
import { toDisplaySalaryDetailsSubSection } from "../utils/toDisplaySalaryDetailsSubSection";

const EmploymentFormDetails = ({
  form,
  inputFields,
  sectionData,
  empGap,
  pushNewEmpRef,
  employmentForm,
  setEmpGap,
  setDisableBtn,
  careerGapsTypes,
  employmentTypes,
  reasonOfLeavingTypes,
  bonusTypes,
  salaryFreq,
}) => {
  const { candidateInitialDetails } = useSelector((state) => state.candidate);

  const { hrOrganizationName, doNotDisplayClientName } =
    candidateInitialDetails;

  const dispatch = useDispatch();

  let isCompanyDetailsfieldsPresent = useMemo(
    () => toDisplayCompanyDetailsSubSection(inputFields),
    [inputFields]
  );

  let isHrDetailsfieldsPresent = useMemo(
    () => toDisplayHrDetailsSubSection(inputFields),
    [inputFields]
  );

  let isSupervisorDetailsfieldsPresent = useMemo(
    () => toDisplaySupervisorDetailsSubSection(inputFields),
    [inputFields]
  );

  let isSalaryDetailsfieldsPresent = useMemo(
    () => toDisplaySalaryDetailsSubSection(inputFields),
    [inputFields]
  );

  return (
    <>
      {form.values.isCareerGapPresent ? (
        <>
          {/*Career gaps */}
          {sectionData?.section?.validationScope?.checkGap &&
            empGap?.length > 0 && (
              <>
                <Box p={3}>
                  <Box display={"flex"} alignItems="center">
                    <InsertDriveFileOutlined />
                    <Typography fontSize={"20px"} fontWeight="500">
                      Career Gaps
                    </Typography>
                  </Box>
                  <Typography fontWeight={500} fontSize={"17px"} mt={2}>
                    Seems You have more than{" "}
                    {sectionData?.section?.validationScope?.gapDurationInMonths}{" "}
                    Months of career gap between your employments, please share
                    below details.
                  </Typography>
                </Box>
                <FieldArray name="employmentsGap" id="employmentsGap">
                  {({ push, remove }) => (
                    <>
                      {empGap?.map((employmentGap, index) => (
                        <Grid container p={3} key={employmentGap.gapStartDate}>
                          <Grid item xs={12} lg={2} mt={{ xs: 0, lg: 3.5 }}>
                            <Typography
                              fontSize={"20px"}
                              textAlign={{
                                xs: "center",
                                lg: "left",
                              }}
                            >
                              Gap {index + 1}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={10}>
                            <Grid
                              container
                              spacing={2}
                              display="flex"
                              alignItems={"center"}
                            >
                              <Grid item xs={12} md={4} mt={2}>
                                {moment(employmentGap.gapStartDate).format(
                                  "DD MMM YYYY"
                                ) !==
                                moment(employmentGap.gapEndDate).format(
                                  "DD MMM YYYY"
                                )
                                  ? moment(employmentGap.gapStartDate).format(
                                      "DD MMM YYYY"
                                    ) +
                                    " to " +
                                    moment(employmentGap.gapEndDate).format(
                                      "DD MMM YYYY"
                                    )
                                  : moment(employmentGap.gapStartDate).format(
                                      "DD MMM YYYY"
                                    )}
                              </Grid>
                              {checkIfFieldToBeDisplayedOrNot(
                                "reasonOfGapId",
                                inputFields
                              ) && (
                                <Grid item xs={12} sm={8}>
                                  <FastField
                                    component={BaseSelect}
                                    name={`employmentsGaps.${index}.reasonOfGapId`}
                                    label={getDynamicFieldLabelName(
                                      "reasonOfGapId",
                                      inputFields,
                                      false
                                    )}
                                    optionLabel="reasonOfGapName"
                                    optionValue="candidatesEmploymentReasonOfGapsId"
                                    options={careerGapsTypes}
                                  />
                                </Grid>
                              )}
                              {checkIfFieldToBeDisplayedOrNot(
                                "employmentAdditionalComments",
                                inputFields
                              ) && (
                                <Grid item xs={12}>
                                  <FastField
                                    rows={3}
                                    multiline
                                    component={BaseTextField}
                                    name={`employmentsGaps.${index}.employmentAdditionalComments`}
                                    label={getDynamicFieldLabelName(
                                      "employmentAdditionalComments",
                                      inputFields,
                                      false
                                    )}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </FieldArray>
              </>
            )}
        </>
      ) : (
        <>
          <FieldArray name="employments" id="employments">
            {({ push, remove }) => {
              pushNewEmpRef.current = push;
              return (
                <>
                  {form?.values?.employments?.length ? (
                    <>
                      {" "}
                      {form?.values?.employments?.map((employment, index) =>
                        employment.selectedTab ? (
                          <>
                            {/* Employment Details */}
                            <DisplayHeadingForMultipleFields
                              index={index}
                              heading="Employment Details"
                              currentValue={employment}
                              sectionValues={form?.values?.employments}
                              fieldArrayName="employments"
                              setFieldValue={form.setFieldValue}
                              runWhenDelete={() =>
                                form.setFieldValue(
                                  "employmentsGaps",
                                  checkGaps(
                                    employmentForm.current.values,
                                    sectionData,
                                    setEmpGap
                                  )
                                )
                              }
                            />

                            {/* Form Employment Details  */}
                            <Box>
                              <Grid container>
                                <Grid item xs={12} md={6}>
                                  <Box px={3}>
                                    <Grid container spacing={2}>
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentCompanyName",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "employmentCompanyName",
                                              inputFields
                                            )}
                                            name={`employments.${index}.employmentCompanyName`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "canContactEmployer",
                                        inputFields
                                      ) && (
                                        <>
                                          <Grid item xs={12}>
                                            <FastField
                                              component={BaseSwitchCard}
                                              label={getDynamicFieldLabelName(
                                                "canContactEmployer",
                                                inputFields,
                                                false
                                              )}
                                              name={`employments.${index}.canContactEmployer`}
                                              index={index}
                                              inputFields={inputFields}
                                            />
                                          </Grid>
                                          {!employment?.canContactEmployer ? (
                                            <Grid item xs={12}>
                                              <FastField
                                                component={BaseDatePicker}
                                                label="What is your official cessation date?*"
                                                name={`employments.${index}.cessationDate`}
                                                minDate={moment().toDate()}
                                                maxDate={moment()
                                                  .add(5, "years")
                                                  .toDate()}
                                                index={index}
                                                views={["year", "month", "day"]}
                                                placeholder="DD-MMM-YYYY"
                                                inputFormat="dd-MMM-yyyy"
                                              />
                                            </Grid>
                                          ) : null}
                                        </>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentTypeId",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <FastField
                                            component={BaseSelect}
                                            label={getDynamicFieldLabelName(
                                              "employmentTypeId",
                                              inputFields
                                            )}
                                            optionLabel="employmentTypeName"
                                            optionValue="candidatesEmploymentTypesId"
                                            name={`employments.${index}.employmentTypeId`}
                                            options={employmentTypes}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentDepartment",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "employmentDepartment",
                                              inputFields
                                            )}
                                            name={`employments.${index}.employmentDepartment`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employeeId",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "employeeId",
                                              inputFields
                                            )}
                                            name={`employments.${index}.employeeId`}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box px={3}>
                                    <Grid container spacing={2}>
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "isCurrentEmployer",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <Field
                                            component={BaseSwitchCard}
                                            label={getDynamicFieldLabelName(
                                              "isCurrentEmployer",
                                              inputFields,
                                              false
                                            )}
                                            name={`employments.${index}.isCurrentEmployer`}
                                            inputFields={inputFields}
                                            toDateFieldName={`employments.${index}.employmentToDate`}
                                            sectionData={sectionData}
                                            setGapsState={setEmpGap}
                                            checkGaps={checkGaps}
                                            index={index}
                                          />
                                        </Grid>
                                      )}{" "}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentFromDate",
                                        inputFields
                                      ) && (
                                        <Grid
                                          item
                                          xs={12}
                                          // md={
                                          //   employment?.isCurrentEmployer
                                          //     ? 12
                                          //     : 6
                                          // }
                                          md={6}
                                        >
                                          <FastField
                                            component={BaseDatePicker}
                                            label={getDynamicFieldLabelName(
                                              "employmentFromDate",
                                              inputFields
                                            )}
                                            name={`employments.${index}.employmentFromDate`}
                                            // minDate={moment(
                                            //   sectionData?.section
                                            //     ?.validationScope
                                            //     ?.startDate
                                            // ).toDate()}
                                            maxDate={moment(
                                              sectionData?.section
                                                ?.validationScope?.endDate
                                            ).toDate()}
                                            checkGaps={checkGaps}
                                            index={index}
                                            views={["year", "month", "day"]}
                                            placeholder="DD-MMM-YYYY"
                                            inputFormat="dd-MMM-yyyy"
                                            sectionData={sectionData}
                                            setGapsState={setEmpGap}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentToDate",
                                        inputFields
                                      ) && (
                                        <Grid
                                          item
                                          xs={12}
                                          md={6}
                                          // display={
                                          //   employment?.isCurrentEmployer
                                          //     ? "none"
                                          //     : "flex"
                                          // }
                                          display="flex"
                                          alignItems="center"
                                        >
                                          {!employment?.isCurrentEmployer ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Field
                                                  component={BaseDatePicker}
                                                  label={getDynamicFieldLabelName(
                                                    "employmentToDate",
                                                    inputFields
                                                  )}
                                                  presentBtn={
                                                    employment?.isCurrentEmployer
                                                  }
                                                  name={`employments.${index}.employmentToDate`}
                                                  // minDate={moment(
                                                  //   sectionData?.section
                                                  //     ?.validationScope
                                                  //     ?.startDate
                                                  // ).toDate()}
                                                  maxDate={moment(
                                                    sectionData?.section
                                                      ?.validationScope?.endDate
                                                  ).toDate()}
                                                  checkGaps={checkGaps}
                                                  index={index}
                                                  views={[
                                                    "year",
                                                    "month",
                                                    "day",
                                                  ]}
                                                  placeholder="DD-MMM-YYYY"
                                                  inputFormat="dd-MMM-yyyy"
                                                  sectionData={sectionData}
                                                  setGapsState={setEmpGap}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={2}
                                                mt={2}
                                                display="flex"
                                                justifyContent="center"
                                              >
                                                <CustomTooltip
                                                  tooltipmaxwidth={400}
                                                  title={
                                                    <>
                                                      We will contact this
                                                      employer either: within 1
                                                      business day from the last
                                                      date of employment you
                                                      provided, or the official
                                                      cessation date you have
                                                      chosen, whichever is
                                                      later.
                                                      <br /> <br />
                                                      If you have not officially
                                                      resigned from your current
                                                      employer, we suggest that
                                                      you give us the exact date
                                                      for the permission to
                                                      contact your employer
                                                      until your resignation has
                                                      been tendered.
                                                    </>
                                                  }
                                                >
                                                  <InfoOutlined />
                                                </CustomTooltip>
                                              </Grid>
                                            </>
                                          ) : (
                                            <Grid item xs={12} mt={2}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked
                                                    disabled
                                                    icon={
                                                      <CheckBoxOutlineBlank />
                                                    }
                                                    checkedIcon={<CheckBox />}
                                                  />
                                                }
                                                label="Present"
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "canContactEmployer",
                                        inputFields
                                      ) && (
                                        <HandleToRenderReasonForCessationDateField
                                          employment={employment}
                                          index={index}
                                          hrOrganizationName={
                                            hrOrganizationName
                                          }
                                          doNotDisplayClientName={
                                            doNotDisplayClientName
                                          }
                                        />
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "employmentStatusId",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12}>
                                          <FastField
                                            component={BaseRadioGroup}
                                            label={getDynamicFieldLabelName(
                                              "employmentStatusId",
                                              inputFields
                                            )}
                                            name={`employments.${index}.employmentStatusId`}
                                            options={[
                                              {
                                                value: 1,
                                                label: "Full Time",
                                              },
                                              {
                                                value: 2,
                                                label: "Part Time",
                                              },
                                            ]}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "jobTitle",
                                        inputFields
                                      ) && (
                                        <Grid
                                          item
                                          xs={12}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "jobTitle",
                                              inputFields
                                            )}
                                            name={`employments.${index}.jobTitle`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "reasonForLeaving",
                                        inputFields
                                      ) && (
                                        <>
                                          <Grid item xs={12}>
                                            <FastField
                                              component={BaseSelect}
                                              label={getDynamicFieldLabelName(
                                                "reasonForLeaving",
                                                inputFields
                                              )}
                                              optionLabel="reasonName"
                                              optionValue="id"
                                              name={`employments.${index}.reasonForLeaving`}
                                              options={reasonOfLeavingTypes}
                                              index={index}
                                            />
                                          </Grid>

                                          {employment?.reasonForLeaving ===
                                          8 ? (
                                            <Grid item xs={12}>
                                              <FastField
                                                component={BaseTextField}
                                                name={`employments.${index}.otherReasonForLeaving`}
                                                shouldUpdate={() => true}
                                                label="Other reason for leaving*"
                                              />
                                            </Grid>
                                          ) : null}
                                        </>
                                      )}
                                    </Grid>
                                  </Box>
                                </Grid>
                                {checkIfFieldToBeDisplayedOrNot(
                                  "reasonForLeaving",
                                  inputFields
                                ) && employment?.reasonForLeaving === 5 ? (
                                  <Grid item xs={12}>
                                    <Box
                                      my={{ xs: 2, md: 1 }}
                                      px={{ xs: 2, md: 5 }}
                                    >
                                      <Box display="flex" alignItems="center">
                                        <FastField
                                          name={`employments.${index}.wasResignationRequested`}
                                          component={BaseSwitchCard}
                                          label={
                                            <Typography
                                              fontSize={{
                                                xs: "16px",
                                                md: "18px",
                                              }}
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              Was the resignation required by
                                              this employer?
                                            </Typography>
                                          }
                                        />
                                        <CustomTooltip
                                          style={{
                                            marginLeft: "10px",
                                          }}
                                          title="This should include any departure other than a completely voluntary resignation which is instigated by you, or an employer's decision not to renew a fixed term contract of employment."
                                        >
                                          <InfoOutlined />
                                        </CustomTooltip>
                                      </Box>

                                      {employment.wasResignationRequested && (
                                        <Box mt={2}>
                                          <FastField
                                            component={BaseTextField}
                                            name={`employments.${index}.reasonOfResignationRequested`}
                                            multiline
                                            minRows={2}
                                            fullWidth
                                            sx={{
                                              fieldset: {
                                                borderRadius: "12px",
                                              },
                                            }}
                                            placeholder="Please provide reason why company has requested you for resignation/Separation?"
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Box>
                            {/* */}

                            {/* Agency Details */}
                            {checkIfFieldToBeDisplayedOrNot(
                              "wasOnPayrollOfAgency",
                              inputFields
                            ) && (
                              <Box>
                                <Box my={2} px={3}>
                                  <Divider />
                                </Box>
                                <Box mt={3} px={3}>
                                  <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className="space-x-2"
                                  >
                                    <InsertDriveFileOutlined />
                                    <Typography
                                      fontSize={"20px"}
                                      fontWeight="500"
                                    >
                                      Agency Details
                                    </Typography>
                                  </Box>
                                  <Grid item xs={12} md={12}>
                                    <FastField
                                      component={BaseSwitchCard}
                                      box={{ mt: 3 }}
                                      label={getDynamicFieldLabelName(
                                        "wasOnPayrollOfAgency",
                                        inputFields
                                      )}
                                      name={`employments.${index}.wasOnPayrollOfAgency`}
                                    />
                                  </Grid>
                                </Box>
                                {get(
                                  form.values,
                                  `employments.${index}.wasOnPayrollOfAgency`
                                ) && (
                                  <Box px={3} mt={3}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          label="Agency Name*"
                                          name={`employments.${index}.agencyName`}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          label="Address Line 1*"
                                          name={`employments.${index}.agencyStreetAddressLine1`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <FastField
                                          component={BaseTextField}
                                          label="Address Line 2"
                                          name={`employments.${index}.agencyStreetAddressLine2`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          label="City/Town/District*"
                                          name={`employments.${index}.agencyCityTownDistrict`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          shouldUpdate={() => true}
                                          label={
                                            form?.values?.employments[index]
                                              ?.agencyCountryId === 101 ||
                                            form?.values?.employments[index]
                                              ?.agencyCountryId === 132
                                              ? "State/Province"
                                              : "State/Province*"
                                          }
                                          name={`employments.${index}.agencyStateProvince`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseCountrySelect}
                                          label="Country*"
                                          name={`employments.${index}.agencyCountryId`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          label="Zip/Postal/Area Code"
                                          name={`employments.${index}.agencyZipPostalAreaCode`}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={3}>
                                        <Typography
                                          varient="span"
                                          sx={{
                                            marginBottom: "4px",
                                          }}
                                        >
                                          Phone Number
                                        </Typography>
                                        <FastField
                                          name={`employments.${index}.agencyPhoneNumber`}
                                          codeName={`employments.${index}.agencyPhoneCountryCode`}
                                          placeholder="Your phone number"
                                          className="round_phone"
                                          component={PhoneNumberTextField}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                )}
                              </Box>
                            )}
                            {/* */}

                            {/* Company Details  */}
                            <Box
                              display={
                                isCompanyDetailsfieldsPresent ? "block" : "none"
                              }
                            >
                              <Box my={3} px={3}>
                                <Divider />
                              </Box>

                              <Box
                                mt={3}
                                px={3}
                                display={"flex"}
                                alignItems="center"
                                className="space-x-2"
                              >
                                <PlaceOutlined />
                                <Typography fontSize={"20px"} fontWeight="500">
                                  Company Details
                                </Typography>
                              </Box>
                              <Box px={3} mt={3}>
                                <Grid container spacing={2}>
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerStreetAddressLine1",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={6}>
                                      <FastField
                                        component={BaseTextField}
                                        label={getDynamicFieldLabelName(
                                          "employerStreetAddressLine1",
                                          inputFields
                                        )}
                                        name={`employments.${index}.employerStreetAddressLine1`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerStreetAddressLine2",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={6}>
                                      <FastField
                                        component={BaseTextField}
                                        label={getDynamicFieldLabelName(
                                          "employerStreetAddressLine2",
                                          inputFields
                                        )}
                                        name={`employments.${index}.employerStreetAddressLine2`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerCityTownDistrict",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={3}>
                                      <FastField
                                        component={BaseTextField}
                                        label={getDynamicFieldLabelName(
                                          "employerCityTownDistrict",
                                          inputFields
                                        )}
                                        name={`employments.${index}.employerCityTownDistrict`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerStateProvince",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={3}>
                                      <FastField
                                        component={BaseTextField}
                                        shouldUpdate={() => true}
                                        name={`employments.${index}.employerStateProvince`}
                                        label={`${getDynamicFieldLabelName(
                                          "employerStateProvince",
                                          inputFields,
                                          false
                                        )}${
                                          // form?.values?.employments[index]
                                          //   ?.employerCountryId === 101 ||
                                          // form?.values?.employments[index]
                                          //   ?.employerCountryId === 132
                                          //   ? ""
                                          //   : "*"
                                          getDynamicFieldLabelNameBasedOnCountryId(
                                            "employerStateProvince",
                                            inputFields,
                                            form?.values?.employments[index]
                                              ?.employerCountryId
                                          )
                                        }`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerCountryId",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={3}>
                                      <FastField
                                        component={BaseCountrySelect}
                                        label={getDynamicFieldLabelName(
                                          "employerCountryId",
                                          inputFields
                                        )}
                                        name={`employments.${index}.employerCountryId`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerZipPostalAreaCode",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={3}>
                                      <FastField
                                        component={BaseTextField}
                                        label={getDynamicFieldLabelName(
                                          "employerZipPostalAreaCode",
                                          inputFields
                                        )}
                                        name={`employments.${index}.employerZipPostalAreaCode`}
                                      />
                                    </Grid>
                                  )}
                                  {checkIfFieldToBeDisplayedOrNot(
                                    "employerPhoneNumber",
                                    inputFields
                                  ) && (
                                    <Grid item xs={12} md={3}>
                                      <Typography
                                        varient="span"
                                        sx={{
                                          marginBottom: "4px",
                                        }}
                                      >
                                        {getDynamicFieldLabelName(
                                          "employerPhoneNumber",
                                          inputFields
                                        )}
                                      </Typography>
                                      <FastField
                                        name={`employments.${index}.employerPhoneNumber`}
                                        codeName={`employments.${index}.employerPhoneCountryCode`}
                                        placeholder="Your phone number"
                                        // error={
                                        //   errors?.phoneNumber && touched?.phoneNumber
                                        // }
                                        // required
                                        className="round_phone"
                                        component={PhoneNumberTextField}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                              {/* */}
                            </Box>

                            {/* HR Details */}
                            <Box
                              display={
                                isHrDetailsfieldsPresent ? "block" : "none"
                              }
                            >
                              <Box my={3} px={3}>
                                <Divider />
                              </Box>

                              <Box>
                                <Box
                                  mt={3}
                                  px={3}
                                  display={"flex"}
                                  alignItems="center"
                                  className="space-x-2"
                                >
                                  <InsertDriveFileOutlined />
                                  <Typography
                                    fontSize={"20px"}
                                    fontWeight="500"
                                  >
                                    HR Details
                                  </Typography>
                                </Box>
                                <Box px={3} mt={3}>
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <Grid container spacing={2}>
                                        {checkIfFieldToBeDisplayedOrNot(
                                          "hrName",
                                          inputFields
                                        ) && (
                                          <Grid item xs={12} md={3}>
                                            <FastField
                                              component={BaseTextField}
                                              label={getDynamicFieldLabelName(
                                                "hrName",
                                                inputFields
                                              )}
                                              name={`employments.${index}.hrName`}
                                            />
                                          </Grid>
                                        )}
                                        {checkIfFieldToBeDisplayedOrNot(
                                          "hrTitle",
                                          inputFields
                                        ) && (
                                          <Grid item xs={12} md={3}>
                                            <FastField
                                              component={BaseTextField}
                                              label={getDynamicFieldLabelName(
                                                "hrTitle",
                                                inputFields
                                              )}
                                              name={`employments.${index}.hrTitle`}
                                            />
                                          </Grid>
                                        )}
                                        {checkIfFieldToBeDisplayedOrNot(
                                          "hrEmail",
                                          inputFields
                                        ) && (
                                          <Grid item xs={12} md={3}>
                                            <FastField
                                              component={BaseTextField}
                                              label={getDynamicFieldLabelName(
                                                "hrEmail",
                                                inputFields
                                              )}
                                              name={`employments.${index}.hrEmail`}
                                            />
                                          </Grid>
                                        )}
                                        {checkIfFieldToBeDisplayedOrNot(
                                          "hrPhoneNumber",
                                          inputFields
                                        ) && (
                                          <Grid item xs={12} md={3}>
                                            <Typography
                                              varient="span"
                                              sx={{
                                                marginBottom: "4px",
                                              }}
                                            >
                                              {getDynamicFieldLabelName(
                                                "hrPhoneNumber",
                                                inputFields
                                              )}
                                            </Typography>
                                            <FastField
                                              name={`employments.${index}.hrPhoneNumber`}
                                              codeName={`employments.${index}.hrPhoneCountryCode`}
                                              placeholder="Phone Number"
                                              className="round_phone"
                                              component={PhoneNumberTextField}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                            {/* */}

                            {/* Supervisor Details  */}
                            <Box
                              display={
                                isSupervisorDetailsfieldsPresent
                                  ? "block"
                                  : "none"
                              }
                            >
                              <Box my={3} px={3}>
                                <Divider />
                              </Box>

                              <Box
                                mt={3}
                                px={3}
                                display={"flex"}
                                alignItems="center"
                                className="space-x-2"
                              >
                                <InsertDriveFileOutlined />
                                <Typography fontSize={"20px"} fontWeight="500">
                                  Supervisor Details
                                </Typography>
                              </Box>
                              <Box px={3} mt={3}>
                                <Grid container>
                                  <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "supervisorName",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12} md={3}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "supervisorName",
                                              inputFields
                                            )}
                                            name={`employments.${index}.supervisorName`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "supervisorTitle",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12} md={3}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "supervisorTitle",
                                              inputFields
                                            )}
                                            name={`employments.${index}.supervisorTitle`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "supervisorEmail",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12} md={3}>
                                          <FastField
                                            component={BaseTextField}
                                            label={getDynamicFieldLabelName(
                                              "supervisorEmail",
                                              inputFields
                                            )}
                                            name={`employments.${index}.supervisorEmail`}
                                          />
                                        </Grid>
                                      )}
                                      {checkIfFieldToBeDisplayedOrNot(
                                        "supervisorPhoneNumber",
                                        inputFields
                                      ) && (
                                        <Grid item xs={12} md={3}>
                                          <Typography
                                            varient="span"
                                            sx={{
                                              marginBottom: "4px",
                                            }}
                                          >
                                            {getDynamicFieldLabelName(
                                              "supervisorPhoneNumber",
                                              inputFields
                                            )}
                                          </Typography>
                                          <FastField
                                            name={`employments.${index}.supervisorPhoneNumber`}
                                            codeName={`employments.${index}.supervisorPhoneCountryCode`}
                                            placeholder="Phone Number"
                                            // error={
                                            //   errors?.phoneNumber && touched?.phoneNumber
                                            // }
                                            // required
                                            className="round_phone"
                                            component={PhoneNumberTextField}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                            {/* */}

                            {/* Salary Details */}
                            <Box
                              display={
                                isSalaryDetailsfieldsPresent ? "block" : "none"
                              }
                            >
                              <Box my={3} px={3}>
                                <Divider />
                              </Box>

                              <Box>
                                <Box px={3} mt={3}>
                                  <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className="space-x-2"
                                  >
                                    <InsertDriveFileOutlined />
                                    <Typography
                                      fontSize={"20px"}
                                      fontWeight="500"
                                    >
                                      Salary Details
                                    </Typography>
                                  </Box>
                                  <Typography
                                    fontWeight={500}
                                    fontSize={"17px"}
                                    mt={2}
                                  >
                                    *Please ensure the numbers you provide here
                                    matches your pay slips.
                                  </Typography>
                                </Box>

                                <Box px={3} mt={3}>
                                  <Grid container spacing={{ xs: 2, md: 3 }}>
                                    {checkIfFieldToBeDisplayedOrNot(
                                      "salaryFrequencyId",
                                      inputFields
                                    ) && (
                                      <>
                                        <Grid item xs={12} md={3}>
                                          <FastField
                                            component={BaseSelect}
                                            label={getDynamicFieldLabelName(
                                              "salaryFrequencyId",
                                              inputFields
                                            )}
                                            name={`employments.${index}.salaryFrequencyId`}
                                            options={salaryFreq}
                                            optionLabel="salaryFrequencyName"
                                            optionValue="candidatesEmploymentSalaryFrequenciesId"
                                          />
                                        </Grid>
                                        {employment.salaryFrequencyId === 6 ? (
                                          <Grid item xs={12} md={3}>
                                            <FastField
                                              component={BaseTextField}
                                              label="Other salary frequency*"
                                              name={`employments.${index}.otherSalaryFrequency`}
                                            />
                                          </Grid>
                                        ) : null}
                                      </>
                                    )}
                                    {checkIfFieldToBeDisplayedOrNot(
                                      "salaryCurrencyId",
                                      inputFields
                                    ) && (
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseCurrencyAutocomplete}
                                          label={getDynamicFieldLabelName(
                                            "salaryCurrencyId",
                                            inputFields
                                          )}
                                          name={`employments.${index}.salaryCurrencyId`}
                                        />
                                      </Grid>
                                    )}
                                    {checkIfFieldToBeDisplayedOrNot(
                                      "salaryAmount",
                                      inputFields
                                    ) && (
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          type="number"
                                          label={getDynamicFieldLabelName(
                                            "salaryAmount",
                                            inputFields
                                          )}
                                          name={`employments.${index}.salaryAmount`}
                                        />
                                      </Grid>
                                    )}
                                    {checkIfFieldToBeDisplayedOrNot(
                                      "otherRemuneration",
                                      inputFields
                                    ) && (
                                      <Grid item xs={12} md={3}>
                                        <FastField
                                          component={BaseTextField}
                                          label={getDynamicFieldLabelName(
                                            "otherRemuneration",
                                            inputFields
                                          )}
                                          name={`employments.${index}.otherRemuneration`}
                                        />
                                      </Grid>
                                    )}
                                    {checkIfFieldToBeDisplayedOrNot(
                                      "wasBonusReceived",
                                      inputFields
                                    ) && (
                                      <Grid item xs={12}>
                                        <Box>
                                          <FastField
                                            component={BaseSwitchCard}
                                            box={{ my: 1 }}
                                            label={getDynamicFieldLabelName(
                                              "wasBonusReceived",
                                              inputFields
                                            )}
                                            name={`employments.${index}.wasBonusReceived`}
                                          />
                                        </Box>
                                        <FieldArray
                                          name={`employments.${index}.bonus`}
                                        >
                                          {({
                                            remove: bonusRemove,
                                            push: bonusPush,
                                          }) => (
                                            <>
                                              {employment.wasBonusReceived && (
                                                <>
                                                  {employmentForm.current?.values?.employments[
                                                    index
                                                  ]?.bonus?.map(
                                                    (
                                                      bonusValue,
                                                      bonusIndex
                                                    ) => (
                                                      <Box
                                                        key={bonusValue.id}
                                                        mt={2}
                                                      >
                                                        <Grid
                                                          container
                                                          xs={12}
                                                          spacing={{
                                                            xs: 2,
                                                          }}
                                                          display="flex"
                                                          alignItems="center"
                                                        >
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            md={
                                                              bonusValue.bonusTypeId ===
                                                              3
                                                                ? 2.5
                                                                : 3.5
                                                            }
                                                          >
                                                            <FastField
                                                              component={
                                                                BaseCurrencyAutocomplete
                                                              }
                                                              label="Currency*"
                                                              name={`employments.${index}.bonus.${bonusIndex}.bonusCurrencyId`}
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            md={
                                                              bonusValue.bonusTypeId ===
                                                              3
                                                                ? 3
                                                                : 4
                                                            }
                                                          >
                                                            <FastField
                                                              component={
                                                                BaseTextField
                                                              }
                                                              type="number"
                                                              label="Last Bonus Amount*"
                                                              name={`employments.${index}.bonus.${bonusIndex}.bonusAmount`}
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            md={
                                                              bonusValue.bonusTypeId ===
                                                              3
                                                                ? 3
                                                                : 4
                                                            }
                                                          >
                                                            <FastField
                                                              component={
                                                                BaseSelect
                                                              }
                                                              name={`employments.${index}.bonus.${bonusIndex}.bonusTypeId`}
                                                              label="Bonus Type*"
                                                              optionLabel="bonusName"
                                                              optionValue="id"
                                                              options={
                                                                bonusTypes
                                                              }
                                                            />
                                                          </Grid>
                                                          {bonusValue.bonusTypeId ===
                                                          3 ? (
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              md={3}
                                                            >
                                                              <FastField
                                                                component={
                                                                  BaseTextField
                                                                }
                                                                type="text"
                                                                label="Other Bonus Type*"
                                                                name={`employments.${index}.bonus.${bonusIndex}.otherBonusTypeName`}
                                                              />
                                                            </Grid>
                                                          ) : null}
                                                          {employmentForm
                                                            .current.values
                                                            .employments[index]
                                                            .bonus.length >
                                                            1 && (
                                                            <Grid
                                                              item
                                                              xs={0.5}
                                                              mt={2}
                                                            >
                                                              <Box>
                                                                <IconButton
                                                                  aria-label="delete"
                                                                  onClick={async (
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();

                                                                    bonusRemove(
                                                                      bonusIndex
                                                                    );
                                                                    await Promise.resolve();
                                                                  }}
                                                                  color="error"
                                                                >
                                                                  <DeleteOutline />
                                                                </IconButton>
                                                              </Box>
                                                            </Grid>
                                                          )}
                                                        </Grid>
                                                      </Box>
                                                    )
                                                  )}
                                                  <Box
                                                    mt={2}
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                  >
                                                    <Button
                                                      type="button"
                                                      color="primary"
                                                      variant="contained"
                                                      onClick={() => {
                                                        bonusPush(
                                                          newBonusData()
                                                        );
                                                      }}
                                                      xs={{
                                                        width: "100%",
                                                      }}
                                                    >
                                                      Add
                                                    </Button>
                                                  </Box>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </FieldArray>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>

                            {/* */}

                            {/* Supporting Documents */}
                            <Box
                              display={
                                checkIfFieldToBeDisplayedOrNot(
                                  "attachmentsExperienceLetter",
                                  inputFields
                                ) ||
                                checkIfFieldToBeDisplayedOrNot(
                                  "attachmentsPayslip",
                                  inputFields
                                )
                                  ? "block"
                                  : "none"
                              }
                            >
                              <Box my={3} px={3}>
                                <Divider />
                              </Box>
                              <Box mt={3}>
                                <Box
                                  px={3}
                                  display={"flex"}
                                  alignItems="center"
                                  className="space-x-2"
                                >
                                  <InsertDriveFileOutlined />
                                  <Typography
                                    fontSize={"20px"}
                                    fontWeight="500"
                                  >
                                    Supporting Documents
                                    {getDynamicFieldLabelName(
                                      "attachmentsExperienceLetter",
                                      inputFields
                                    )?.includes("*") ||
                                    getDynamicFieldLabelName(
                                      "attachmentsPayslip",
                                      inputFields
                                    )?.includes("*")
                                      ? "*"
                                      : ""}
                                  </Typography>
                                </Box>
                                <Box
                                  display={
                                    checkIfFieldToBeDisplayedOrNot(
                                      "attachmentsExperienceLetter",
                                      inputFields
                                    )
                                      ? "block"
                                      : "none"
                                  }
                                >
                                  <BaseUploadNew
                                    multiple
                                    mx={3}
                                    mt={3}
                                    label={getDynamicFieldLabelName(
                                      "attachmentsExperienceLetter",
                                      inputFields
                                    )}
                                    onChange={(files, delObj, delAll) =>
                                      addAndRemoveImageHanlderArray({
                                        files,
                                        delObj,
                                        delAll,
                                        form,
                                        refForm: employmentForm,
                                        index,
                                        keyForArray: "employments",
                                        categoryName: "EMPLOYMENT_DOCS",
                                        dispatch,
                                        setDisableBtn,
                                      })
                                    }
                                    name={`employments.${index}.attachments`}
                                    id={`accordion${form.values.employments.length}`}
                                    uploadFiles={employment?.attachments}
                                    form={form}
                                    checkName={`employments.${index}.attachments`}
                                    categoryName="EMPLOYMENT_DOCS"
                                    key={`${index}_D`}
                                    setDisableBtn={setDisableBtn}
                                  />

                                  <Box my={3} px={3}>
                                    <Divider />
                                  </Box>
                                </Box>
                                <Box
                                  display={
                                    checkIfFieldToBeDisplayedOrNot(
                                      "attachmentsPayslip",
                                      inputFields
                                    )
                                      ? "block"
                                      : "none"
                                  }
                                >
                                  <BaseUploadNew
                                    multiple
                                    mx={3}
                                    my={3}
                                    label={getDynamicFieldLabelName(
                                      "attachmentsPayslip",
                                      inputFields
                                    )}
                                    onChange={(files, delObj, delAll) =>
                                      addAndRemoveImageHanlderArray({
                                        files,
                                        delObj,
                                        delAll,
                                        form,
                                        refForm: employmentForm,
                                        index,
                                        keyForArray: "employments",
                                        categoryName: "EMPLOYMENT_PAYSLIPS",
                                        dispatch,
                                        setDisableBtn,
                                      })
                                    }
                                    id={`accordion${form.values.employments.length}`}
                                    uploadFiles={employment?.attachments}
                                    form={form}
                                    checkName={`employments.${index}.attachments`}
                                    categoryName="EMPLOYMENT_PAYSLIPS"
                                    name={`employments.${index}.attachments`}
                                    key={`${index}_P`}
                                    setDisableBtn={setDisableBtn}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            {/* */}
                          </>
                        ) : null
                      )}
                    </>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography align="center" fontWeight={550} p={2}>
                        No Employments Present
                      </Typography>
                    </Box>
                  )}
                </>
              );
            }}
          </FieldArray>
        </>
      )}
    </>
  );
};

export default EmploymentFormDetails;
