import * as pdfjsLib from "pdfjs-dist";

// Use CDN-hosted worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;

export const convertPdfUrlToImg = async (pdfUrl) => {
  const loadingTask = pdfjsLib.getDocument(pdfUrl);
  const pdf = await loadingTask.promise;

  const pages = [];
  for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
    const page = await pdf.getPage(pageNumber);

    const viewport = page.getViewport({ scale: 2 });

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    let canvasContext = { canvasContext: context };

    // if (pageNumber === 1) {
    //   canvas.height = viewport.height - 50;
    //   canvas.width = window.innerWidth;

    //   // create a new viewport with the reduced scale to adjust first page
    //   const reducedViewport = page.getViewport({ scale: 1.95 });

    //   context.translate(80, 0); // Shift everything to the right

    //   canvasContext = {
    //     ...canvasContext,
    //     viewport: reducedViewport,
    //   };
    // } else {
    //   canvas.height = viewport.height - 10;
    //   canvas.width = viewport.width;

    //   canvasContext = {
    //     ...canvasContext,
    //     viewport,
    //   };
    // }
    //changes
    canvas.height = viewport.height - 50;
    canvas.width = window.innerWidth;

    // create a new viewport with the reduced scale to adjust first page

    context.translate(40, 0); // Shift everything to the right

    canvasContext = {
      ...canvasContext,
      viewport,
    };

    //changes end

    await page.render(canvasContext).promise;

    // Convert the canvas to an image data URL
    const imageDataUrl = canvas.toDataURL("image/png");

    pages.push(imageDataUrl);
  }

  return pages;
};
